.jesus-is-god-page {
  max-width: 1200px;
  margin: 0 auto;
  padding: 2rem 1rem;
  text-align: left;
}

.jesus-intro {
  text-align: center;
  margin-bottom: 3rem;
}

.jesus-intro h1 {
  color: #1e3a8a;
  font-size: 2.5rem;
  margin-bottom: 1rem;
}

.jesus-description {
  font-size: 1.1rem;
  line-height: 1.6;
  color: #333;
  max-width: 800px;
  margin: 0 auto;
}

.hebrews-section,
.divinity-evidence {
  margin-bottom: 3rem;
}

.hebrews-section h2,
.divinity-evidence h2 {
  text-align: center;
  color: #1e3a8a;
  font-size: 1.8rem;
  margin-bottom: 1.5rem;
}

.verses-container {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  gap: 1.5rem;
}

.evidence-container {
  display: flex;
  flex-direction: column;
  gap: 2rem;
}

.evidence-card {
  background-color: #f8f9fa;
  border-radius: 8px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
  padding: 1.5rem;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.evidence-card:hover {
  transform: translateY(-5px);
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.15);
}

.evidence-title {
  color: #1e3a8a;
  font-size: 1.4rem;
  margin-bottom: 1rem;
  border-bottom: 2px solid #4b59f7;
  padding-bottom: 0.5rem;
}

.evidence-verses {
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
}

.evidence-verse {
  background-color: #fff;
  padding: 1rem;
  border-radius: 6px;
  border-left: 4px solid #4b59f7;
}

.verse-reference {
  font-weight: bold;
  color: #444;
  margin-bottom: 0.5rem;
}

.verse-text {
  font-style: italic;
  color: #333;
  margin-bottom: 0.75rem;
}

.verse-explanation {
  font-size: 0.95rem;
  line-height: 1.5;
  color: #444;
}

/* Clickable card styling */
.verse-card.clickable {
  cursor: pointer;
  transition: all 0.3s ease;
  position: relative;
  overflow: hidden;
}

.verse-card.clickable:hover {
  transform: translateY(-5px);
  box-shadow: 0 10px 15px rgba(0, 0, 0, 0.1);
}

.verse-card.clickable::after {
  content: "Click for more details";
  position: absolute;
  bottom: 0;
  right: 0;
  background-color: #1e3a8a;
  color: white;
  padding: 0.25rem 0.5rem;
  font-size: 0.7rem;
  border-radius: 4px 0 0 0;
  opacity: 0;
  transition: opacity 0.3s ease;
}

.verse-card.clickable:hover::after {
  opacity: 1;
}

.verse-card.active {
  border: 2px solid #1e3a8a;
  box-shadow: 0 5px 15px rgba(30, 58, 138, 0.2);
  background-color: #f0f9ff;
}

/* Animation for content changes */
.weekly-post {
  transition: opacity 0.3s ease;
  animation: fadeIn 0.5s ease;
}

@keyframes fadeIn {
  from { opacity: 0; transform: translateY(10px); }
  to { opacity: 1; transform: translateY(0); }
}

@media screen and (max-width: 768px) {
  .jesus-intro h1 {
    font-size: 1.8rem;
  }
  
  .jesus-description {
    font-size: 1rem;
  }
  
  .hebrews-section h2,
  .divinity-evidence h2 {
    font-size: 1.5rem;
  }
  
  .verses-container {
    grid-template-columns: 1fr;
  }
  
  .evidence-title {
    font-size: 1.3rem;
  }
}