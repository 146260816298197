.about-page {
  max-width: 1200px;
  margin: 0 auto;
  padding: 2rem 1rem;
  text-align: left;
}

.about-intro {
  text-align: center;
  margin-bottom: 3rem;
}

.about-intro h1 {
  color: #1e3a8a;
  font-size: 2.5rem;
  margin-bottom: 1rem;
}

.about-description {
  font-size: 1.1rem;
  line-height: 1.6;
  color: #333;
  max-width: 800px;
  margin: 0 auto 2rem;
}

.about-mission,
.about-beliefs,
.about-content,
.about-connect {
  margin-bottom: 3rem;
}

.about-mission h2,
.about-beliefs h2,
.about-content h2,
.about-connect h2 {
  text-align: center;
  color: #1e3a8a;
  font-size: 1.8rem;
  margin-bottom: 1.5rem;
}

.about-mission p,
.about-content p,
.about-connect p {
  font-size: 1.1rem;
  line-height: 1.6;
  color: #333;
  margin-bottom: 1rem;
  max-width: 800px;
  margin-left: auto;
  margin-right: auto;
}

.beliefs-container {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  gap: 1.5rem;
  max-width: 1100px;
  margin: 0 auto;
}

.belief-card {
  background-color: #f8f9fa;
  border-radius: 8px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
  padding: 1.5rem;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.belief-card:hover {
  transform: translateY(-5px);
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.15);
}

.belief-card h3 {
  color: #1e3a8a;
  font-size: 1.4rem;
  margin-bottom: 1rem;
  border-bottom: 2px solid #4b59f7;
  padding-bottom: 0.5rem;
}

.belief-card p {
  font-size: 1rem;
  line-height: 1.5;
  color: #444;
}

.content-list {
  list-style-type: disc;
  padding-left: 2rem;
  margin-bottom: 1.5rem;
  max-width: 800px;
  margin-left: auto;
  margin-right: auto;
}

.content-list li {
  font-size: 1.1rem;
  line-height: 1.6;
  color: #333;
  margin-bottom: 0.5rem;
}

/* Animation for content changes */
.belief-card {
  animation: fadeIn 0.5s ease;
}

@keyframes fadeIn {
  from { opacity: 0; transform: translateY(10px); }
  to { opacity: 1; transform: translateY(0); }
}

@media screen and (max-width: 768px) {
  .about-intro h1 {
    font-size: 1.8rem;
  }
  
  .about-description,
  .about-mission p,
  .about-content p,
  .about-connect p {
    font-size: 1rem;
  }
  
  .about-mission h2,
  .about-beliefs h2,
  .about-content h2,
  .about-connect h2 {
    font-size: 1.5rem;
  }
  
  .beliefs-container {
    grid-template-columns: 1fr;
  }
  
  .belief-card h3 {
    font-size: 1.3rem;
  }
  
  .content-list li {
    font-size: 1rem;
  }
}