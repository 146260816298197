.navbar {
  background: #1a1a2e;
  height: 80px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1.2rem;
  position: sticky;
  top: 0;
  z-index: 999;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.3);
}

.navbar-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 80px;
  max-width: 1500px;
  padding: 0 24px;
}

.navbar-logo {
  color: #fff;
  justify-self: start;
  cursor: pointer;
  text-decoration: none;
  font-size: 1.5rem;
  display: flex;
  align-items: center;
  font-weight: bold;
}

.nav-menu {
  display: flex;
  align-items: center;
  justify-content: center;
  list-style: none;
  text-align: center;
  margin-right: 24px;
}

.nav-item {
  height: 80px;
  border-bottom: 2px solid transparent;
}

.nav-item:hover {
  border-bottom: 2px solid #4b59f7;
}

.nav-link {
  color: #fff;
  display: flex;
  align-items: center;
  text-decoration: none;
  padding: 0.5rem 1rem;
  height: 100%;
}

.nav-link:hover {
  color: #4b59f7;
  transition: all 0.3s ease;
}

/* Menu icon for mobile */
.menu-icon {
  display: none;
}

@media screen and (max-width: 960px) {
  .navbar {
    position: relative;
  }

  .nav-menu {
    display: flex;
    flex-direction: column;
    width: 100%;
    position: absolute;
    top: 80px;
    left: -100%;
    opacity: 1;
    transition: all 0.5s ease;
  }

  .nav-menu.active {
    background: #1a1a2e;
    left: 0;
    opacity: 1;
    transition: all 0.5s ease;
    z-index: 1;
  }

  .nav-item {
    height: 60px;
    width: 100%;
    display: flex;
    justify-content: center;
  }

  .nav-link {
    text-align: center;
    padding: 2rem;
    width: 100%;
    display: table;
  }

  .nav-link:hover {
    color: #4b59f7;
    transform: scale(1.2);
    transition: all 0.3s ease;
  }
  
  /* Mobile menu icon styles */
  .menu-icon {
    display: block;
    position: absolute;
    top: 0;
    right: 0;
    transform: translate(-100%, 60%);
    font-size: 1.8rem;
    cursor: pointer;
    color: #fff;
  }
  
  /* Simple hamburger and close icons using text characters */
  .menu-icon i.fas.fa-bars:before {
    content: '☰';
  }
  
  .menu-icon i.fas.fa-times:before {
    content: '✕';
  }
}
