.header-container {
  width: 100%;
  position: relative;
}

.header-image-container {
  width: 100%;
  height: 300px;
  position: relative;
  overflow: hidden;
}

.header-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
}

.header-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.4);
  color: white;
  text-align: center;
}

.header-overlay h1 {
  font-size: 2.5rem;
  margin-bottom: 0.5rem;
  letter-spacing: 2px;
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.7);
}

.header-overlay p {
  font-size: 1.2rem;
  letter-spacing: 1px;
  text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.7);
}

@media screen and (max-width: 768px) {
  .header-overlay h1 {
    font-size: 1.8rem;
  }
  
  .header-overlay p {
    font-size: 1rem;
  }
}
