.individual-card {
  background-color: #f8f9fa;
  border-radius: 8px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
  padding: 1.5rem;
  margin-bottom: 2rem;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.individual-card:hover {
  transform: translateY(-5px);
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.15);
}

.verse-reference {
  color: #1a1a2e;
  font-size: 1.5rem;
  margin-bottom: 1rem;
  border-bottom: 2px solid #4b59f7;
  padding-bottom: 0.5rem;
}

.verse-content {
  margin-bottom: 1rem;
}

.verse-text {
  font-size: 1.1rem;
  line-height: 1.6;
  font-style: italic;
  color: #333;
}

.verse-explanation {
  background-color: #f0f0f7;
  padding: 1rem;
  border-left: 4px solid #4b59f7;
  border-radius: 0 4px 4px 0;
}

.verse-explanation p {
  font-size: 1rem;
  line-height: 1.5;
  color: #444;
}

@media screen and (max-width: 768px) {
  .individual-card {
    padding: 1rem;
  }
  
  .verse-reference {
    font-size: 1.3rem;
  }
  
  .verse-text {
    font-size: 1rem;
  }
}
