.weekly-post {
  background-color: #fff;
  border-radius: 8px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
  padding: 1.5rem;
  margin-bottom: 2rem;
}

.post-header {
  margin-bottom: 1rem;
}

.post-title {
  font-size: 1.6rem;
  color: #1e3a8a;
  margin-bottom: 0.5rem;
}

.post-meta {
  display: flex;
  flex-wrap: wrap;
  gap: 1rem;
  font-size: 0.9rem;
  color: #666;
  margin-bottom: 0.5rem;
}

.post-date,
.post-author,
.post-type {
  display: inline-flex;
  align-items: center;
}

.post-date::before {
  content: "📅";
  margin-right: 0.3rem;
}

.post-author::before {
  content: "✍️";
  margin-right: 0.3rem;
}

.post-type::before {
  content: "📚";
  margin-right: 0.3rem;
}

.post-content {
  line-height: 1.6;
  color: #333;
}

.post-content p {
  margin-bottom: 1rem;
}

.post-references {
  margin-top: 1.5rem;
  padding-top: 1rem;
  border-top: 1px solid #eee;
}

.post-references h4 {
  font-size: 1.1rem;
  color: #1e3a8a;
  margin-bottom: 0.5rem;
}

.post-references ul {
  padding-left: 1.5rem;
  margin-bottom: 0;
}

.post-references li {
  margin-bottom: 0.3rem;
}

/* Style based on post type */
.salvation-post {
  border-left: 4px solid #3b82f6;
}

.hymn-post {
  border-left: 4px solid #8b5cf6;
}

.armor-post {
  border-left: 4px solid #10b981;
}

/* Preview styling */
.read-more-hint {
  font-style: italic;
  color: #6b7280;
  font-size: 0.9rem;
  margin-top: 0.5rem;
}

/* Responsive adjustments */
@media screen and (max-width: 768px) {
  .post-title {
    font-size: 1.4rem;
  }
  
  .post-meta {
    flex-direction: column;
    gap: 0.5rem;
  }
  
  .weekly-post {
    padding: 1rem;
  }
}